@import url('https://fonts.cdnfonts.com/css/exo-2');
.form-control:focus {
  border-color: #00e69b !important;
  -webkit-box-shadow: 0 0 5px #00e69b !important;
  box-shadow: 0 0 5px #00e69b !important;

}
html body{
  background:#f4f0ed;
  font-family: 'Exo 2', sans-serif;
}
.bgCream{
  background:#f4f0ed;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navi{
  display: inline;
  list-style: none;
}
ul.navi li {
  float: right;
  margin: 2%;
}
ul.navi li a{
  text-decoration: none;
  color:#282c34;
  font-family: 'Exo 2', sans-serif;
  font-size:18pt;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
ul.navi li a:hover{
  color:#00e69b;
  font-size:19pt;
}
.btn-primary{
  background:#00e69b !important;
  border:none !important;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
.btn-primary:hover{
  background-color: #282c34 !important;
}
.formLabel{
  background:#00e69b;
  padding:2%;
  color:#f4f0ed;
}
.spanTitle::after{
  background : url(./img/labelAfter.png) no-repeat;
  content : "";
  height: inherit;
  width: -webkit-fill-available;
  max-width: 50%;
}
a.active{
  color:#00e69b !important;
  font-size: 19pt !important;
}
.footerLabel{
  font-size: 15pt;
  color: #00e69b;
  text-shadow: 1px 1px 3px #ccc;
}
footer p{
  font-size:13pt;
}
footer ul {
  list-style: none;
  padding:1%;
}
footer ul li a {
  text-decoration: none;
  color: crimson;
  font-size: 13pt;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
footer ul li a:hover{
  color:#00e69b;
}
label.contentLabel{
  color: #00e69b;
  font-size: 18pt;
}
label.titleMainHuge{
  font-size: 22pt;
  color:#333;
  font-weight: bolder;
}
label.titleMainLight{
  font-size: 14pt;
}
.haveSubmenu:hover .submenu{
  display:flex !important;
  position: absolute;
  width:auto;
  list-style: none;
  padding: 1% 2%;
  background: #fff;
  border-bottom: solid 0.5px #eee;
}
h1.articleTitle{
  color: #00a76f;
  font-size: 32pt;
  margin-bottom:5% !important;
}
h1.mainTitle{
  color: #333;
  font-size: 32pt;
  margin-bottom:5% !important;
}
article p{
  font-size: 16pt;
}
button.closeModalBtn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 34pt;
  color: #333;
  background: none;
  border: none;
  padding: 1% 3%;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
button.closeModalBtn:hover{
  color:#fff;
  background:#00a76f;
}
.howToTitle{
  color:#00e69b;
}
.blogMainEntry{
  border:solid 0.5px #f4f0ed;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
.blogMainEntry:hover{
  border-color: #00e69b;
  cursor:pointer;
  padding:.5%;
}
.telLink{
  color:#00a76f;
  text-decoration: none;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
.telLink:hover{
  color:crimson;
}
.entryText {
  margin: 5%;
  padding: 3%;
  background: aquamarine;
  font-size: 12pt;
}
.contentText{
  font-size:14pt;
}
.uploader__file_input{
  display: none;
}
.uploader__file{
  max-width: 30%
}
.uploader__btn{
  display: none;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
.formIcon{
  width: 100% !important;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
.formIcon:hover{
  color:#00a76f;
  border: solid 0.5px #00e69b;
  cursor:pointer;
}
.uploader__container{
  width: 100%;
  text-align: center;
}
label#file_uploader {
  width: 100%;
}
.bigIcon{
  font-size:60pt !important;
}
.iconBox .formIcon{
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
.iconBox:hover .formIcon{
  color:#00e69b;
}
.controlBtn{
  color:#00a76f;
  border:solid 0.2px #ddd;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
.controlBtn:hover{
  color:#333;
  background-color: #00e69b;
  border-radius: 5px;
}
.pan-container img{
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
.pan-container:hover img{
  opacity:0.5
}
.infoZamowienie {
  background: antiquewhite;
  padding: 5%;
  border: solid 0.2px #f00;
}
.linkSwal{
  text-decoration: none;
  color:#00a76f;
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}
.linkSwal:hover{
  color:#61dafb;
}
.useScrollInfo{
    position: absolute;
    margin-right: -35%;
    width: 15%;
    transform: rotate(45deg);
}
.podsumowanieKosztow {
  padding: 1%;
  background: #f00;
  position: fixed;
  color: #fff;
  bottom:5%;
  left:0;
  font-size: 12pt;
}